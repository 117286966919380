const revSrc = [
  "https://d35408cyocioye.cloudfront.net/new3/micro-page/02-rev-micro-multiple-micros1.mp4",
  "https://d35408cyocioye.cloudfront.net/new3/micro-page/03-rev-micro-wall-mount1.mp4",
  "https://d35408cyocioye.cloudfront.net/new3/micro-page/04-rev-micro-sensorsL.mp4",
  "https://d35408cyocioye.cloudfront.net/new3/micro-page/05-rev-micro-touchL.mp4",
  "https://d35408cyocioye.cloudfront.net/new3/micro-page/06-rev-micro-zoom1.mp4",
  "https://d35408cyocioye.cloudfront.net/new3/micro-page/07-rev-micro-end1.mp4",
];

$(window).load(function () {
  $("#heroVid").addClass("is-playing");
  let heroVid = $("#heroVid")[0];
  heroVid.muted = "muted";
  heroVid.play();

  let fadeInDelay = 0;
  const mqTitle = window.matchMedia("(min-width: 1024px)");
  if (mqTitle.matches) {
    fadeInDelay = 6500;
  }

  setTimeout(function () {
    $(".main-title").css("opacity", "1");
    $(".scrollDownArrow").css("opacity", "1");

    //Scroll down arrow
    setTimeout(function () {
      $(".scrollDownArrow").css("opacity", "1");
      $(".arrowLines").css("width", "15px");
      $(".arrowLines").css("height", "3px");
    }, 300);
    setTimeout(function () {
      $(".arrowLines1")
        .css("transform", "rotate(-35deg)")
        .css("margin-left", "-2px");
      $(".arrowLines2")
        .css("transform", "rotate(35deg)")
        .css("margin-right", "-2px");
    }, 600);
  }, fadeInDelay);

  //NAV
  $(".fullNavTextHome").removeClass("active");
  $(".fullNavTextMicro").addClass("active");
  $(".fullNavTextMicro").bind("click", function (e) {
    e.preventDefault();
  });

  var hoverCount = 0;
  $(".scrollDownArrow").hover(function () {
    hoverCount++;
    //even odd hover detect
    var isEven = function (someNumber) {
      return someNumber % 2 === 0 ? true : false;
    };
    // on even hover do this
    if (isEven(hoverCount) === true) {
      // animate back to standard arrow
      $(".arrowLines").css("width", "15px");
      $(".downArrowLine").removeClass("animated");
      $(".arrowLines1")
        .css("transform", "rotate(-35deg)")
        .css("margin-left", "-2px");
      $(".arrowLines2")
        .css("transform", "rotate(35deg)")
        .css("margin-right", "-2px");
    }
    // on odd hover do this
    else if (isEven(hoverCount) === false) {
      // animated scroll down
      $(".arrowLines").css("width", "18px");
      $(".downArrowLine").addClass("animated");
      $(".arrowLines1")
        .css("transform", "rotate(-215deg)")
        .css("margin-left", "-2px");
      $(".arrowLines2")
        .css("transform", "rotate(215deg)")
        .css("margin-right", "-2px");
    }
  });

  $(".scrollDownArrow").click(function (e) {
    e.preventDefault();
    // built in fullpage function to move one section down
    $.fn.fullpage.moveSectionDown();
  });

  //Email form on mobile
  $(".joinText").on("click", function (e) {
    const mq3 = window.matchMedia("(max-width: 769px)");

    if (mq3.matches) {
      $(".joinText").css("top", "5%");
      $("#email_signup").css("display", "block");
    }
  });

  //Mobile nav reconfig
  const mqTab = window.matchMedia("(min-width: 1024px)");
  if (!mqTab.matches) {
    $("#fullNavListRightLogin").remove();
    $(".horizontalAboutLine").remove();
    let aboutLinks = [];
    $("#fullNavListMid li").each(function () {
      aboutLinks.push(this);
    });
    $(aboutLinks).insertBefore(".fullNavTextGetJosh");
  }

  // video.src = videoFile;
  // video.load();
  // video.play();
  // $('.fp-section.active').css('background', 'url('+bgImg[1]+')');
  let video = $("#micro-transitionVid")[0];
  let revVid = $("#micro-reverseVid")[0];
  let revVidSrc = $("#micro-reverseVid source")[0];
  let scrollingSpeed = 1370;

  $("#backToTop").each(function () {
    $(this).click(function (e) {
      video.load();
      e.preventDefault();
      $.fn.fullpage.moveTo(1);
    });
  });

  //Load first video
  video.load();
  revVid.load();

  const mq0 = window.matchMedia("(max-width: 1024px)");

  if (mq0.matches) {
    scrollingSpeed = 800;
  }

  // FULLPAGE INITIALIZATION (scrolljack)
  debugger;
  $("#micro-fullpage").fullpage({
    // no vertical center or css3 transitions
    verticalCentered: false,
    scrollingSpeed: scrollingSpeed,
    fitToSection: false,
    scrollOverflow: true,
    normalScrollElements: ".fullNav",
    normalScrollElementTouchThreshold: 5,

    // SECTION LOAD CALLBACKS
    afterLoad: function (anchorLink, index) {
      const mq = window.matchMedia("(max-width: 1024px)");
      //Mobile actions
      if (mq.matches) {
        if (index == 1) {
          $(".micro-transition-container").css("opacity", "1");
          $(".overtext-container").css("opacity", "1");
        } else if (index == 2) {
        }
      } else {
        //Desktop actions
        video.currentTime = (index - 2) * 1.36;

        if (index == 1) {
          $(".micro-transition-container").css("opacity", "1");
          $(".overtext-container").css("opacity", "1");
          $(".links").removeClass("not-1");
        } else if (index == 2) {
          $("#micro-reverseVid").css("opacity", "0");
          $(".links").addClass("not-1");
        } else if (index > 2 && index < 9) {
          //
          revVidSrc.src = revSrc[index - 3];
          revVid.load();
          $("#micro-reverseVid").css("opacity", "0");
        }

        if (index > 1) {
          $('.newnav-container').addClass('newnav-container-background');
          $('.newnav-category').addClass('newnav-category-light')
          $('.newnav-category').removeClass('newnav-category-dark')
          $('#josh-logo-lighttheme').removeClass('transparent')
          $('#josh-logo-darktheme').addClass('transparent')
        }
      }

      //Back to top arrow
      if (index == 10) {
        $("#backToTop").css("display", "block").css("opacity", "0.5");
      } else {
        $("#backToTop").css("opacity", "0").css("display", "none");
      }
    },
    // END SECTION LOAD CALLBACKS
    // SECTION EXIT CALLBACKS
    onLeave: function (index, nextIndex, direction) {
      const mq2 = window.matchMedia("(max-width: 1024px)");
      //Mobile actions
      if (mq2.matches) {
        if (index == 1 && direction == "down") {
          //
        } else if (index == 2 && direction == "up") {
        }
      } else {
        //Desktop actions

        if (index > 1 && index < 8 && direction == "down") {
          //Play vid
          video.muted = "muted";
          video.play();
          setTimeout(function () {
            video.pause();
          }, 1360);
        }

        if (index > 2 && index < 9 && direction == "up") {
          //set src to prev video, set to end, step through backwards at .1 the speed
          revVid.muted = "muted";
          revVid.play();
          $("#micro-reverseVid").css("opacity", "1");
          // console.log(video.currentTime);
          video.currentTime = (index - 3) * 1.36;
          // console.log(video.currentTime);
        }
      }
    },
    // END SECTION EXIT CALLBACKS
  });
  // END FULLPAGE INITIALIZATION
});
